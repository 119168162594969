import { HistoryServiceV3 } from '@feature-hub/history-service';

export const clearOneLayerQuery = (layerLength: number, historyService: HistoryServiceV3) => {
  const hasDeepLink =
    window.location.hash.includes('#one-layer=') || window.location.hash.includes('#oneLayer=');
  if (hasDeepLink) {
    historyService?.history.go(-layerLength);
    historyService.rootHistory.replace(``, {});
  }
};
